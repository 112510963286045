import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import LocalLibrary from "@mui/icons-material/LocalLibrary";
import Settings from "@mui/icons-material/Settings";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useTranslation } from "react-i18next";
import metadata from "../metadata.json";
import Modal from "@mui/material/Modal";
import UserGuide from "./UserGuide";

// This component provides always accessible interaction with the App
//		* Major page navigation
//		* Settings menu
//		* Online / Offline / AppOffline indicator
//		* Help '?'
//		* Watching for and notifying user about available upgrades

const HlmdToolbar = React.forwardRef((props, ref) => {
	const hideBack = props.hideBack;
	const handleBack = props.handleBack;
	const pages = props.pages;
	const handlePageSelect = props.handlePageSelect;
	const settings = props.settings;
	const cloud = props.cloud;
	const handleNetChange = props.handleNetChange;
	const handleSetting = props.handleSetting;
	const v = metadata.version;
	const { t } = useTranslation();
	const vLabel = t("Version") + " " + v.major + "." + v.minor + "." + v.revision;
	const [helpOpen, setHelpOpen] = React.useState(false);
	// network and cloud status, w/ net event listener
	const [netStatus, setNetStatus] = React.useState(0);
	const netIcons = [
		<WifiIcon disabled={true} />,
		<WifiOffIcon disabled={true} />,
		<CloudOffIcon disabled={true} />
	];

	const updateNetStatus = React.useCallback(() => {
		if (handleNetChange) handleNetChange(window.navigator.onLine);
		const newStatus = cloud ? (window.navigator.onLine ? 0 : 1) : 2;
		if (newStatus !== netStatus) setNetStatus(newStatus);
	}, [cloud, handleNetChange, netStatus]);

	// Drop down menu controls
	const [anchorElNav, setAnchorElNav] = React.useState(null);
	const [anchorElUser, setAnchorElUser] = React.useState(null);
	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleCloseNavMenu = (index) => {
		setAnchorElNav(null);
		handlePageSelect(index); // pass new page selection up to App
	};
	const handleOpenHelp = (event) => {
		setHelpOpen(true);
	};
	const handleCloseHelp = (event) => {
		setHelpOpen(false);
	};
	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};
	const handleCloseUserMenu = (index, subindex) => {
		console.log("handleCloseSettings " + index + " " + subindex);
		setAnchorElUser(null);
		if (index >= 0) handleSetting(index, subindex);
	};

	// Helper routine for cascading drop down menus
	function SubMenu(props) {
		const config = props.config;
		const index = props.index;
		const [anchorEl, setAnchorEl] = React.useState(null);
		//console.log("SubMenu " + config.label);
		//console.log(anchorEl);
		//console.log(config);
		const handleOpen = (event) => {
			setAnchorEl(event.currentTarget);
		};
		const handleClose = (item) => {
			setAnchorEl(null);
			props.onClose(item);
		};
		return (
			<>
				<MenuItem key={"setting" + index} onClick={handleOpen}>
					<Typography textAlign="center">{config.label}</Typography>
				</MenuItem>
				<Menu
					key={"menu-" + config.label + index}
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center"
					}}
					keepMounted
					transformOrigin={{
						vertical: "top",
						horizontal: "left"
					}}
					open={Boolean(anchorEl)}
					onClick={() => {
						handleClose(-1);
					}}
				>
					{config.menu.map((label, index) => (
						<MenuItem
							key={label}
							onClick={() => {
								handleClose(index);
							}}
						>
							<Typography textAlign="center">{label}</Typography>
						</MenuItem>
					))}
				</Menu>
			</>
		);
	}

	React.useEffect(() => {
		window.addEventListener("online", () => updateNetStatus());
		window.addEventListener("offline", () => updateNetStatus());
		updateNetStatus();
	}, [updateNetStatus]);

	return (
		<Toolbar
			id="toolbar"
			disableGutters
			variant="dense"
			sx={{ width: "100%", mx: 0, flexGrow: 0 }}
		>
			{/* First an optional back button */}
			{!hideBack && (
				<IconButton
					size="large"
					onClick={handleBack}
					color="inherit"
					sx={{ py: 1, pl: 1, pr: 0.5, mr: 0 }}
				>
					<ChevronLeft />
				</IconButton>
			)}
			{/* collapsed menu for extra small screens */}
			<Box sx={{ flexGrow: 1, display: { xs: "flex", sm: "none" } }}>
				<IconButton
					size="large"
					onClick={handleOpenNavMenu}
					color="inherit"
					sx={{ px: 0.5, mx: 0.5 }}
				>
					<MoreVertIcon />
				</IconButton>
				<Menu
					id="menu-appBar"
					anchorEl={anchorElNav}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center"
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "left"
					}}
					open={Boolean(anchorElNav)}
					onClose={handleCloseNavMenu}
					sx={{
						display: { xs: "block", sm: "none" }
					}}
				>
					{pages.map((page, index) => (
						<MenuItem
							key={page}
							onClick={() => {
								handleCloseNavMenu(index);
							}}
						>
							<Typography textAlign="center">{page}</Typography>
						</MenuItem>
					))}
				</Menu>
			</Box>
			{/* Then, the menu items spread out on the toolbar for medium+ screens */}
			<Box sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}>
				{pages.map((page, index) => (
					<Button
						key={page}
						onClick={() => {
							handleCloseNavMenu(index);
						}}
						sx={{ my: 1, color: "white", display: "block" }}
					>
						{page}
					</Button>
				))}
			</Box>
			{/* Next, title/brand icon (LocalLibrary) and text, only showing on medium or above*/}
			{/* Layout parameters are specified separately for xs and sm+ */}
			<Box sx={{ display: "flex", flexGrow: 1 }}>
				<Tooltip title={vLabel}>
					<LocalLibrary
						fontSize="small"
						sx={{ display: { xs: "none", sm: "block" }, px: 0, mt: 0.5 }}
					/>
				</Tooltip>
				<Tooltip title={vLabel}>
					<LocalLibrary
						sx={{ display: { xs: "block", sm: "none" }, pr: 0.5, mt: 0.5 }}
					/>
				</Tooltip>

				<Typography
					variant="h6"
					noWrap
					sx={{
						ml: 1,
						mr: 4,
						display: { xs: "none", sm: "flex" },
						flexGrow: "1",
						flexShrink: "2",
						fontFamily: "monospace",
						fontWeight: 700,
						letterSpacing: ".1rem",
						color: "inherit",
						textDecoration: "none"
					}}
				>
					<div className="HTcanOverlay">{t("HeartLMD")}</div>
				</Typography>
				<Typography
					variant="h6"
					noWrap
					sx={{
						mx: 0,
						display: { xs: "flex", sm: "none" },
						flexGrow: "1",
						fontFamily: "monospace",
						fontWeight: 650,
						letterSpacing: ".08rem",
						color: "inherit",
						textDecoration: "none"
					}}
				>
					<div className="HTcanOverlay">{t("HeartLMD")}</div>
				</Typography>
			</Box>
			{/* Help button */}
			<IconButton color="inherit" onClick={handleOpenHelp} sx={{ p: 1 }}>
				<QuestionMarkIcon fontSize="small" sx={{ px: 0.2, mx: 0 }} />
			</IconButton>
			{/* Network and cloud status */}
			{netIcons[netStatus]}
			{/* Finally, the settings menu for all screens, anchored to the right , display: 'block'  */}
			<Box sx={{ flexGrow: 0, flexShrink: 0 }}>
				<Tooltip title={t("ShowSettings")}>
					<IconButton onClick={handleOpenUserMenu} sx={{ p: 1 }}>
						<Settings sx={{ ml: 0.5, mr: 1, my: 0, color: "white" }} />
					</IconButton>
				</Tooltip>
				<Menu
					sx={{ mt: "40px" }}
					id="appBarSettingsMenu"
					anchorEl={anchorElUser}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center"
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right"
					}}
					open={Boolean(anchorElUser)}
					onClose={() => {
						handleCloseUserMenu(-1);
					}}
				>
					{settings.map((setting, index) =>
						setting.menu ? (
							<SubMenu
								key={"setting" + index}
								config={setting}
								index={index}
								onClose={(subindex) => {
									handleCloseUserMenu(index, subindex);
								}}
							/>
						) : (
							<MenuItem
								key={"setting" + index}
								onClick={() => {
									handleCloseUserMenu(index);
								}}
								disabled={setting.disabled}
							>
								<Typography textAlign="center">
									{setting.label}
								</Typography>
							</MenuItem>
						)
					)}
				</Menu>
			</Box>
			<Modal open={helpOpen} onClose={handleCloseHelp}>
				<UserGuide />
			</Modal>
		</Toolbar>
	);
});

export default HlmdToolbar;
