// Converts an ArrayBuffer directly to base64, without any intermediate 'convert to string
// then use window.btoa' step. According to my tests, this appears to be a faster approach:
// http://jsperf.com/encoding-xhr-image-data/5

//Modified to remove '=' padding

/*
MIT LICENSE
Copyright 2011 Jon Leighton
Permission is hereby granted, free of charge, to any person obtaining a copy of this 
software and associated documentation files (the "Software"), to deal in the Software 
without restriction, including without limitation the rights to use, copy, modify, merge, 
publish, distribute, sublicense, and/or sell copies of the Software, and to permit persons 
to whom the Software is furnished to do so, subject to the following conditions:
The above copyright notice and this permission notice shall be included in all copies 
or substantial portions of the Software.
THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING 
BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND 
NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, 
DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, 
OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
*/

let base64Chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
let base64Lookup = new Uint8Array(256);

function Uint8ToBase64(arrayBuffer) {
	// this is NOT URL SAFE; chang +/ to -_ and it will be
	let base64 = "";
	let encodings = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

	let bytes = new Uint8Array(arrayBuffer);
	let byteLength = bytes.byteLength;
	let byteRemainder = byteLength % 3;
	let mainLength = byteLength - byteRemainder;
	let a, b, c, d;
	let chunk;

	// Main loop deals with bytes in chunks of 3
	for (let i = 0; i < mainLength; i = i + 3) {
		// Combine the three bytes into a single 24 bit integer
		chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

		// Use bitmask to extract 6-bit segments from the triplet
		a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
		b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
		c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
		d = chunk & 63; // 63       = 2^6 - 1

		// Convert the raw binary segments to the appropriate ASCII encoding
		base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
	}

	// Deal with the remaining bytes and padding
	if (byteRemainder === 1) {
		chunk = bytes[mainLength]; // 8 bits
		a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2
		b = (chunk & 3) << 4; // 3   = 2^2 - 1
		base64 += encodings[a] + encodings[b]; // + "==";
	} else if (byteRemainder === 2) {
		// 16 bits
		chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];
		a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
		b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4
		c = (chunk & 15) << 2; // 15    = 2^4 - 1
		base64 += encodings[a] + encodings[b] + encodings[c]; // + "=";
	}
	return base64;
}

function base64ToUint8(base64string) {
	// reverse of above, public domain
	let len = base64string.length;
	let extra = len & 3; // 0, 2 or 3 base64
	len -= extra;
	extra = extra === 0 ? 0 : extra - 1; // 0, 1 or 2 bytes
	let bufferLength = len * 0.75 + extra;
	let i = 0,
		p = 0;
	let encoded1, encoded2, encoded3, encoded4;

	// if (base64string[len - 1] === "=") { // padding removed from public domain version
	// 	bufferLength--;
	// 	if (base64string[len - 2] === "=") {
	// 		bufferLength--;
	// 	}
	// }

	// Use a lookup table to find the index
	// let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
	// let lookup = new Uint8Array(256);
	if (base64Lookup[1] === 0) {
		// not yet initialized
		for (i = 0; i < base64Chars.length; i++) {
			base64Lookup[base64Chars.charCodeAt(i)] = i;
		}
	}
	let arrayBuffer = new ArrayBuffer(bufferLength);
	let bytes = new Uint8Array(arrayBuffer);

	for (i = 0; i < len; i += 4) {
		encoded1 = base64Lookup[base64string.charCodeAt(i)];
		encoded2 = base64Lookup[base64string.charCodeAt(i + 1)];
		encoded3 = base64Lookup[base64string.charCodeAt(i + 2)]; // lookup on '=' returns 0 (padding)
		encoded4 = base64Lookup[base64string.charCodeAt(i + 3)];

		bytes[p++] = (encoded1 << 2) | (encoded2 >> 4);
		bytes[p++] = ((encoded2 & 15) << 4) | (encoded3 >> 2);
		bytes[p++] = ((encoded3 & 3) << 6) | (encoded4 & 63);
	}
	if (extra > 0) {
		encoded1 = base64Lookup[base64string.charCodeAt(len)];
		encoded2 = base64Lookup[base64string.charCodeAt(len + 1)];
		bytes[p++] = (encoded1 << 2) | (encoded2 >> 4);
		if (extra === 2) {
			encoded3 = base64Lookup[base64string.charCodeAt(i + 2)];
			bytes[p] = ((encoded2 & 15) << 4) | (encoded3 >> 2);
		}
	}
	return bytes;
}

export { Uint8ToBase64, base64ToUint8 };
