import React from "react";
import { Slide } from "@mui/material";
import useScrollTrigger from "@mui/material/useScrollTrigger";

// const useHideOnScroll = (options) => {
// 	const { threshold, scroller } = options;

// 	const scrollRef = React.useRef();
// 	const [hide, setHide] = React.useState(false);
// 	const thresholdValue = React.useRef(threshold || 100);

// 	React.useEffect(() => {
// 		window.addEventListener("scroll", handleScroll);
// 		window.addEventListener("click", handleClick);
// 		return () => {
// 			window.removeEventListener("scroll", handleScroll);
// 			window.removeEventListener("click", handleClick);
// 		};
// 	}, [handleScroll, handleClick]);

// 	return hide;
// };

export default function HideOnScroll(props) {
	const { children, threshold, scroller, ...other } = props;
	const [dynamicThreshold, setDynamicThreshold] = React.useState(threshold);

	function getScrollY(scroller) {
		return scroller.pageYOffset !== undefined
			? scroller.pageYOffset
			: scroller.scrollTop !== undefined
				? scroller.scrollTop
				: (
						document.documentElement ||
						document.body.parentNode ||
						document.body
					).scrollTop;
	}
	const handleClick = React.useCallback(
		(event) => {
			console.log("HOS click");
			if (dynamicThreshold !== threshold) {
				setDynamicThreshold(threshold);
			} else {
				const scrollY = getScrollY(scroller || window);
				if (scrollY < threshold) return;
				setDynamicThreshold(threshold + scrollY);
			}
			event.preventDefault();
		},
		[dynamicThreshold, scroller, threshold]
	);

	const handleScroll = React.useCallback(() => {
		if (dynamicThreshold === threshold) return;
		const scrollY = getScrollY(scroller || window);
		if (scrollY < dynamicThreshold)
			setDynamicThreshold(Math.min(scrollY, threshold));
	}, [dynamicThreshold, scroller, threshold]);

	React.useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		window.addEventListener("click", handleClick);
		return () => {
			window.removeEventListener("scroll", handleScroll);
			window.removeEventListener("click", handleClick);
		};
	}, [handleScroll, handleClick]);

	const hide = useScrollTrigger({
		target: scroller,
		threshold: dynamicThreshold,
		disableHysteresis: false
	});

	return (
		<Slide
			appear={false}
			direction="down"
			in={!hide}
			timeout={{ enter: 160, exit: 120 }}
			{...other}
		>
			{children}
		</Slide>
	);
}
