import * as safe from "./safe";

// track recent use of content
async function update(node) {
	// update or add newest
	//console.log("update for "+node.shortName+" "+node.verse);
	let dateTime = Date.now() / 1000;
	let found = false;
	try {
		let start = Date.now(); // milliseconds
		let recent = await getAll();
		let elapsed = Date.now() - start;
		// console.log("get history " + elapsed + "ms");
		// console.log(recent);
		if (recent !== null && recent.length > 0) {
			//console.log(recent.length+" nodes")
			let newKey = node.path;
			for (let i = 0; i < recent.length; i++) {
				let ni = recent[i];
				let key = ni.path;
				if (key === newKey) {
					let isUnder12h =
						!ni.dateTime || dateTime - ni.dateTime < 10 * 60 * 12;
					if (!isUnder12h) break; // don't update existing, add new
					found = true;
					let isOver10s = ni.dateTime && dateTime - ni.dateTime > 10;
					let process = isOver10s || significantChange(ni, node);
					if (!process) return;
					if (node.verse)
						ni.verse = node.verse; // track changing verse
					else if (ni.time) ni.time = node.time; // track running time
					// console.log("updated " + ni.shortName + " " + ni.verse);
					ni.dateTime = dateTime; // update existing node
					if (i > 0) {
						recent.splice(i, 1); // remove from current location
						recent.unshift(ni); // put back at head of list
						//console.log("moved "+recent[0].shortName+" "+recent[0].verse);
					}
					break;
				}
			}
		}
		if (!found) {
			// or unknown tracking type
			let newNode = JSON.parse(JSON.stringify(node)); // don't store requester's reference
			newNode.dateTime = dateTime;
			recent.unshift(newNode);
			//console.log("added node "+newNode.verse);
		}
		recent = recent.slice(0, 36); // 36 entries at most ?
		start = Date.now();
		await putAll(recent);
		elapsed = Date.now() - start;
		console.log("put history " + elapsed + "ms");

		//console.log("stored "+recent.length+" nodes");
	} catch (e) {
		console.log(e);
	}
}
function significantChange(n1, n2) {
	if (n1.verse) {
		const parts1 = n1.verse.split(":");
		const parts2 = n2.verse.split(":");
		const deltaVerse = parts2[1] - parts1[1];
		const result = parts1[0] !== parts2[0] || deltaVerse > 2 || deltaVerse < -2;
		return result;
	} else if (n1.time) {
		const deltaTime = n2.time - n1.time;
		return deltaTime > 2 || deltaTime < -2;
	}
}

async function getAll() {
	//console.log("getAll...");
	let storage = window["localStorage"];
	let encrypted = storage.getItem("recent");
	if (encrypted === null) return [];
	let rString = await safe.makeClear(encrypted);
	//console.log(rString);
	let recent = rString && JSON.parse(rString);
	//console.log(recent);
	if (!recent || !recent[0]) recent = [];
	return recent;
}
async function putAll(recent) {
	let storage = window["localStorage"];
	let rString = JSON.stringify(recent);
	//console.log("putAll "+rString);
	let encrypted = await safe.makeOpaque(rString);
	storage.setItem("recent", encrypted);
}

function clear() {
	let storage = window["localStorage"];
	let recent = storage.getItem("recent");
	if (recent) storage.removeItem("recent");
}

export { update, getAll, clear };
